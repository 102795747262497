import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import type { RootState } from 'src/redux/store';

interface ProductConfigProps {
  isNeedMoreClicked: boolean;
}

const initialState: ProductConfigProps = {
  isNeedMoreClicked: false,
};

const ProductConfigSlice = createSlice({
  name: 'productConfig',
  initialState,
  reducers: {
    setIsNeedMoreClicked: (state, action: PayloadAction<boolean>) => {
      state.isNeedMoreClicked = action.payload;
    },
  },
});

export const { setIsNeedMoreClicked } = ProductConfigSlice.actions;
export const selectIsNeedMoreClicked = (state: RootState): boolean =>
  state.productConfig.isNeedMoreClicked;
export default ProductConfigSlice.reducer;
