import * as Sentry from '@sentry/nextjs';
import ErrorFallback from '@components/Error/ErrorFallback';
import React, { ErrorInfo } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type MyProps = {};

type MyState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: MyProps): {
    hasError: boolean;
  } {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here
    Sentry.captureException({ error, errorInfo });
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    // Check if the error is thrown
    if (hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback />;
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
