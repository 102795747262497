const getBasketProductUrl = (url: string): string => {
  const hasProductInUrl = url.indexOf('product/') !== -1;

  let productUrl = url.slice(url.indexOf('product/'), url.length);
  if (!hasProductInUrl) {
    productUrl = `product/${url}`;
  }

  return `/${productUrl}`;
};

export default getBasketProductUrl;
