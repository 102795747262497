import Head from 'next/head';
import Company from 'types/company';
import TelNumber from '@components/Global/TelNumber';

const ErrorFallback = (): JSX.Element => {
  const companyID = +process.env.NEXT_PUBLIC_COMPANY_ID;

  return (
    <div className="m--error-container">
      <div className="m--error-context">
        <Head>
          <title>
            Something went wrong! Sorry about that. |
            {companyID === Company.Ironmongery ? 'Ironmongery' : 'Electrical'}
            Direct Ltd
          </title>
        </Head>
        <h2 className="m--error-context-heading">Something went wrong! Sorry about that.</h2>
        <p className="m--error-context-subheading">
          We seem to be having an issue. Please try again later.
        </p>
        <p className="m--error-context-help-contact">
          Call us on <TelNumber /> and we would be happy to help.
        </p>
      </div>
    </div>
  );
};

export default ErrorFallback;
