interface IProps {
  showManutanGroupLinks: boolean;
}

const manutanGroupList = [
  {
    className: 'mr-0 mt-0 hover:underline 1192px:mt-6 1192px:mr-4',
    link: 'https://www.manutan.com/en/homepage',
    title: 'Who We Are',
  },
  {
    className: 'mr-4 mt-6 hover:underline',
    link: 'https://www.manutan.com/en/homepage/join-us',
    title: 'Join Us',
  },
  {
    className: 'mr-4 mt-6 hover:underline',
    link: 'https://www.manutan.com/blog/en',
    title: 'Group Blog',
  },
];

const ManutanGroupLinks: React.FC<IProps> = ({ showManutanGroupLinks }): JSX.Element => {
  return (
    <ul
      className={`manutan-group-links flex flex-col flex-wrap transition-all duration-500 overflow-hidden 1192px:max-h-screen 1192px:opacity-100 1192px:visible ${
        showManutanGroupLinks ? 'max-h-screen opacity-100 visible' : 'max-h-0 opacity-0 invisible'
      }`}
    >
      {manutanGroupList.map(({ className, link, title }, i) => (
        <li key={i} className={`${className} text-sm md:text-base`}>
          <a href={link} className="hover: text-current">
            {title}
          </a>
        </li>
      ))}
      {/* <li className="mr-0 mt-0 hover:underline 1192px:mt-6 1192px:mr-4">
        <a href="https://www.manutan.com/en/homepage" className="hover: text-current">
          Who We Are
        </a>
      </li>
      <li className="mr-4 mt-6 hover:underline">
        <a href="https://www.manutan.com/en/homepage/join-us" className="hover: text-current">
          Join Us
        </a>
      </li>
      <li className="mr-4 mt-6 hover:underline">
        <a href="https://www.manutan.com/en/homepage/our-investors" className="hover: text-current">
          Our Investors
        </a>
      </li>
      <li className="mr-4 mt-6 hover:underline">
        <a href="https://www.manutan.com/blog/en" className="hover: text-current">
          Group Blog
        </a>
      </li> */}
    </ul>
  );
};

export default ManutanGroupLinks;
