export const flameIconList = [
  '/browse/door-closers-and-fire-exit-hardware',
  '/browse/cabinet-and-shelf-hardware',
  '/browse/door-furniture',
  '/browse/door-furniture/door-handles-and-knobs/door-handle-kits',
  '/browse/door-furniture/door-handles-and-knobs/door-handles-on-backplate',
  '/browse/door-furniture/door-handles-and-knobs/door-handles-on-rose',
  '/browse/door-furniture/door-handles-and-knobs/door-knobs',
  '/browse/sliding-door-gear',
  '/browse/locks-safety-and-security/door-locks-latches-and-security/mortice-locks-and-latches',
  '/browse/locks-safety-and-security/door-locks-latches-and-security/budget-and-cabinet-locks',
  '/browse/cabinet-and-shelf-hardware/cabinet-handles-and-knobs/cabinet-handles',
  '/browse/cabinet-and-shelf-hardware/cabinet-handles-and-knobs/cabinet-knobs',
  '/browse/window-furniture-and-hardware/casement-hardware/casement-stays-and-fasteners',
  '/browse/window-furniture-and-hardware/window-operating-systems/window-operators',
  '/browse/hinges-seals-and-intumescent/hinges/cabinet-hinges',
  '/browse/hinges-seals-and-intumescent/hinges/door-hinges',
  '/browse/bathroom-and-cubicle-hardware/bathroom-hardware/wall-mounted-bathroom-accessories',
  '/browse/bathroom-and-cubicle-hardware/washroom-and-cubicle-hardware/cubicle-components',
  '/browse/gate-fence-and-balustrades/gate-and-shed-hardware/gate-hinges',
  '/browse/gate-fence-and-balustrades/gate-and-shed-hardware/gate-bolts',
  '/browse/screws-fixings-and-adhesives/screws-and-fixings/nuts-bolts-and-washers',
  '/browse/screws-fixings-and-adhesives/screws-and-fixings/woodscrews',
  '/browse/screws-fixings-and-adhesives/screws-and-fixings/self-tapping-screws',
  '/browse/sockets-and-wiring/smart-switches-and-receivers/smart-receivers',
  '/browse/sockets-and-wiring/sockets-and-switches/dimmers',
  '/browse/lighting/cabinet-lights/led-cabinet-downlights',
  '/browse/lighting/ceiling-lights/pendant-lights',
  '/browse/heating-and-ventilation/heating/hand-dryers',
  '/browse/heating-and-ventilation/heating/portable-heaters',
];
