import axios from 'axios';
import { FbEventData } from 'types/facebook';

export const fbEvent = async (data: FbEventData): Promise<void> => {
  try {
    axios({
      method: 'POST',
      url: `/api/fbEvents`,
      data,
    });
  } catch {
    console.error('Failed to send fb event data.');
  }
};
