import { Action, configureStore, EnhancedStore, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import configReducer from 'src/redux/configSlice';
import progressReducer from 'src/redux/progressSlice';
import checkoutReducer from 'src/redux/checkoutSlice';
import userReducer from 'src/redux/userSlice';
import sortedReducer from 'src/redux/sortedSlice';
import vatReducer from 'src/redux/VATSlice';
import basketReducer from 'src/redux/basketSlice';
import brandReducer from 'src/redux/brandSlice';
import categoryReducer from 'src/redux/categorySlice';
import historyReducer from 'src/redux/historySlice';
import productConfigReducer from 'src/redux/productConfigSlice';
import dyContextReducer from 'src/redux/dyContextSlice';
import foundItReducer from 'src/redux/foundItSlice';
import navMenuReducer from 'src/redux/navmenuSlice';

export const store = configureStore({
  reducer: {
    config: configReducer,
    progress: progressReducer,
    checkout: checkoutReducer,
    user: userReducer,
    sorted: sortedReducer,
    vat: vatReducer,
    basket: basketReducer,
    brand: brandReducer,
    category: categoryReducer,
    history: historyReducer,
    productConfig: productConfigReducer,
    dyContext: dyContextReducer,
    foundIt: foundItReducer,
    navMenu: navMenuReducer,
  },
  // Useful if you want to add some custom middleware but still want the default middleware redux toolkit provides.
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV === 'development',
});

export const makeStore = (): EnhancedStore => store;

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

export const wrapper = createWrapper(makeStore, {
  debug: process.env.NODE_ENV === 'development',
});
