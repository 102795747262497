import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
// import { useRouter } from 'next/router';
// import Link from 'next/link';
// import { GetServerSideProps } from 'next';

import Company from 'types/company';
import InfiniteLoader from '@components/infiniteLoader';
import { setCardCode } from 'src/redux/userSlice';
import AccountSignup from './signup';

interface Props {
  onClose: () => void;
  isRegister?: boolean;
  fromNav?: boolean;
  onLogin?: () => void;
}

interface InitialValueProps {
  emailAddress: string;
  password: string;
}

interface InitialFormValues {
  emailAddress: string;
}

const SignInModal = ({ onClose, isRegister, onLogin, fromNav }: Props): JSX.Element => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const baseUrl = process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL;
  const apiKey = process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY;
  const environment = process.env.NEXT_PUBLIC_APP_ENV;
  const dispatch = useDispatch();

  const [display, setDisplay] = useState<'login' | 'register' | 'forgot'>(
    isRegister ? 'register' : 'login'
  );
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);

  const initialValues: InitialValueProps = {
    emailAddress: '',
    password: '',
  };

  const initialFormValues: InitialFormValues = {
    emailAddress: '',
  };

  const TTALoginSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Email address is required and must be valid')
      .required('Email address is required and must be valid'),
    password: Yup.string().required('Password required'),
  });

  const TTAForgotPasswordSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Email address is required and must be valid')
      .required('Email address is required and must be valid'),
  });

  const modalBackgroundRef = useRef<HTMLDivElement>(null);

  return (
    <div
      tabIndex={-1}
      role="button"
      onClick={(event) => {
        if (event.target === modalBackgroundRef.current) onClose();
      }}
      ref={modalBackgroundRef}
      className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 z-50 flex items-end lg:items-center justify-center overflow-hidden cursor-default"
    >
      <div className="w-full lg:w-4/5 h-90vh lg:h-80% bg-white flex rounded-t-xl lg:rounded-xl overflow-hidden xs:border-t-8 border-orange-dark max-w-screen-xl">
        <div className="w-full xs:w-1/2 py-7 xs:py-10 px-5 xs:px-30px lg:p-14 overflow-y-scroll relative xs:block">
          <div className="z-1 absolute top-0 left-0 xs:hidden flex w-full justify-end">
            <button
              onClick={() => {
                onClose();
              }}
            >
              <img
                src="https://img-cdn.manutantraders.com/image/upload/v1719392556/etp/close_icon.png"
                className="w-10 h-10 mb-1 mt-2 mr-2"
                alt="close"
              />
            </button>
          </div>
          {fromNav ? (
            <div className="flex flex-row justify-between">
              <h3 className="text-sm md:text-xl">Create an account</h3>
              <img
                className="h-8 w-32 lg:h-9 lg:w-40 mr-7 md:mr-0"
                src="/images/ID-Light-bg.svg"
                alt="IronmongeryDirect logo"
              />
            </div>
          ) : (
            <img
              className="h-8 w-32 lg:h-9 lg:w-40"
              src="/images/ID-Light-bg.svg"
              alt="IronmongeryDirect logo"
            />
          )}
          <div className="flex flex-col w-full">
            {display === 'login' && (
              <>
                <div className="border-b-1 border-grey-light">
                  <button
                    className="text-lg xs:text-xl mt-5 text-orange inline-block border-b-4 border-orange pb-4 mr-10"
                    onClick={() => {
                      setDisplay('login');
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="text-lg xs:text-xl mt-5 inline-block pb-4"
                    onClick={() => {
                      setDisplay('register');
                    }}
                  >
                    Register
                  </button>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    setIsLoginError(false);
                    setIsLoginLoading(true);
                    axios({
                      method: 'POST',
                      url: `${baseUrl}/account/api/Auth/Login?companyID=${companyID}`,
                      headers: {
                        'ocp-apim-subscription-key': apiKey,
                      },
                      data: { username: values.emailAddress, password: values.password },
                    })
                      .then((response) => {
                        document.cookie = `authToken=${response.data}; max-age=86400; path=/; ${
                          environment === 'production'
                            ? `domain=.${
                                companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                              }direct.co.uk;`
                            : ''
                        }`;
                        document.cookie = `authEmail=${
                          values.emailAddress
                        }; max-age=86400; path=/; ${
                          environment === 'production'
                            ? `domain=.${
                                companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                              }direct.co.uk;`
                            : ''
                        }`;
                        window.ScarabQueue.push(['setEmail', values.emailAddress]);
                        window.ScarabQueue.push(['go']);
                        dispatch(setCardCode({ companyID, baseUrl, apiKey, token: response.data }));
                        setIsLoginLoading(false);
                        onClose();
                        onLogin();
                      })
                      .catch(() => {
                        document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
                          environment === 'production'
                            ? `domain=.${
                                companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                              }direct.co.uk;`
                            : ''
                        }`;
                        document.cookie = `authEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;${
                          environment === 'production'
                            ? `domain=.${
                                companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
                              }direct.co.uk;`
                            : ''
                        }`;
                        setIsLoginError(true);
                        setIsLoginLoading(false);
                      });
                  }}
                  validationSchema={TTALoginSchema}
                >
                  {({ values, handleChange, errors, touched }) => {
                    return (
                      <Form className="space-y-8">
                        <div className="relative w-full mt-6">
                          <Field
                            type="text"
                            name="emailAddress"
                            value={values.emailAddress}
                            className={`basicInput${
                              values.emailAddress === '' ? '' : ' active'
                            } relative appearance-none bg-transparent w-full pr-8 py-3.5 border-b focus:outline-none z-1 ${
                              touched.emailAddress
                                ? errors.emailAddress
                                  ? 'border-error'
                                  : 'border-valid'
                                : 'border-grey-light'
                            }`}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="emailAddress"
                            className="absolute left-0 z-0 origin-0 duration-300"
                          >
                            Email Address
                          </label>
                          {touched.emailAddress && errors.emailAddress && (
                            <p className="text-xs text-red mt-1">{errors.emailAddress}</p>
                          )}
                        </div>
                        <div className="relative w-full mt-3">
                          <Field
                            type="password"
                            name="password"
                            value={values.password}
                            className={`basicInput${
                              values.password === '' ? '' : ' active'
                            } relative appearance-none bg-transparent w-full pr-8 py-3.5 border-b focus:outline-none z-1 ${
                              touched.password
                                ? errors.password
                                  ? 'border-error'
                                  : 'border-valid'
                                : 'border-grey-light'
                            }`}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="password"
                            className="absolute left-0 z-0 origin-0 duration-300"
                          >
                            Password
                          </label>
                          {touched.password && errors.password && (
                            <p className="text-xs text-red mt-1">{errors.password}</p>
                          )}
                        </div>
                        {isLoginError && (
                          <div className="flex flex-row space-x-4">
                            <div className="flex">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="times"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 352 512"
                                data-fa-i2svg=""
                                width={20}
                                fill="#cc4646"
                                className="m-auto"
                              >
                                <path
                                  fill="#cc4646"
                                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                ></path>
                              </svg>
                            </div>
                            <div className="text-error">
                              Your email address or password was not recognized. Please try again or{' '}
                              <button
                                onClick={() => setDisplay('forgot')}
                                className="underline bg-transparent font-bold focus:outline-none"
                              >
                                reset your password
                              </button>
                              .
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col xl:flex-row w-full items-center">
                          <button
                            type="submit"
                            className={`text-xs lg:text-sm font-bold h-full group flex justify-center align-middle items-center transition ease-in-out duration-300 focus:outline-none focus-visible:shadow-outline w-full xl:w-200px max-h-button rounded-button py-2 lg:py-2.5 ${
                              values.emailAddress === '' || values.password === ''
                                ? 'bg-grey-lightestx3 cursor-not-allowed text-grey-darker'
                                : companyID === Company.Ironmongery
                                ? 'bg-orange hover:bg-orange-dark cursor-pointer text-black-lighter'
                                : 'bg-green hover:bg-green-dark cursor-pointer text-black-lighter'
                            } xl:mr-8`}
                          >
                            {isLoginLoading ? (
                              <InfiniteLoader
                                className={'bg-transparent block w-150px h-14 text-16px leading-6'}
                              />
                            ) : (
                              'Login'
                            )}
                          </button>
                          <div className="w-full xl:w-auto flex justify-center mt-4 xl:mt-0">
                            <button
                              type="button"
                              className="underline mx-auto cursor-pointer text-sm"
                              onClick={() => setDisplay('forgot')}
                            >
                              Forgotten your password?
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
            {display === 'register' && (
              <>
                {!fromNav && (
                  <div className="border-b-1 border-grey-light">
                    <button
                      className="text-lg xs:text-xl mt-5 inline-block mr-10"
                      onClick={() => {
                        setDisplay('login');
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="text-lg xs:text-xl mt-5 inline-block pb-4 text-orange border-orange border-b-4"
                      onClick={() => {
                        setDisplay('register');
                      }}
                    >
                      Register
                    </button>
                  </div>
                )}
                <AccountSignup
                  onRegister={() => {
                    if (fromNav) {
                      onClose();
                    } else {
                      onLogin();
                      onClose();
                    }
                  }}
                  onReset={() => {
                    setDisplay('forgot');
                  }}
                />
              </>
            )}
            {display === 'forgot' && (
              <>
                <h2 className="text-xl mt-5 mb-2 inline-block pb-2">Forgotten Password?</h2>
                <Formik
                  initialValues={initialFormValues}
                  onSubmit={(values) => {
                    setIsLoading(true);
                    axios({
                      method: 'POST',
                      url: `${baseUrl}/account/forgottenMyPassword?emailAddress=${values.emailAddress}&companyID=${companyID}`,
                      headers: {
                        'ocp-apim-subscription-key': apiKey,
                        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
                      },
                      data: {
                        email: values.emailAddress,
                      },
                    })
                      .then((res) => {
                        document.body.style.overflow = 'auto';
                        setMessage(res?.data?.message);
                        setIsSuccess(
                          res?.data?.message !==
                            'Sorry, there is no record of an account with that email address.'
                            ? true
                            : false
                        );
                        setIsError(
                          res?.data?.message ===
                            'Sorry, there is no record of an account with that email address.'
                            ? true
                            : false
                        );
                        setIsLoading(false);
                      })
                      .catch(() => {
                        setIsError(true);
                        setMessage(
                          'Sorry, there is no record of an account with that email address.'
                        );
                        setIsLoading(false);
                      });
                  }}
                  validationSchema={TTAForgotPasswordSchema}
                >
                  {({ values, handleChange, errors, touched }) => {
                    return (
                      <Form className="space-y-8">
                        <div className="relative w-full mt-3">
                          <Field
                            type="text"
                            name="emailAddress"
                            value={values.emailAddress}
                            className={`basicInput${
                              values.emailAddress === '' ? '' : ' active'
                            } relative appearance-none bg-transparent w-full pr-8 py-3.5 border-b focus:outline-none z-1 ${
                              touched.emailAddress
                                ? errors.emailAddress
                                  ? 'border-error'
                                  : 'border-valid'
                                : 'border-grey-light'
                            }`}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                          <label
                            htmlFor="emailAddress"
                            className="absolute left-0 z-0 origin-0 duration-300"
                          >
                            Email Address
                          </label>
                          {errors.emailAddress && (
                            <p className="text-xs text-red mt-1">{errors.emailAddress}</p>
                          )}
                        </div>
                        {isError && <div className="text-error text-sm">{message}</div>}
                        {isSuccess && <div className="text-sm">{message}</div>}
                        <div className="flex flex-col lg:flex-row w-full">
                          <div className="w-full lg:w-1/2">
                            <button
                              type="submit"
                              className={`text-xs lg:text-sm font-bold h-full group flex justify-center align-middle items-center transition ease-in-out duration-300 focus:outline-none focus-visible:shadow-outline text-black-lighter w-full lg:w-56 lg:max-h-button rounded-button py-2 lg:py-2.5 mb-4 lg:mb-0 ${
                                values.emailAddress === ''
                                  ? 'bg-grey-lightestx3 cursor-not-allowed text-grey-darker'
                                  : companyID === Company.Ironmongery
                                  ? 'bg-orange hover:bg-orange-dark'
                                  : 'bg-green hover:bg-green-dark'
                              }`}
                            >
                              {isLoading ? (
                                <InfiniteLoader
                                  className={'bg-transparent block w-full h-14 text-16px leading-6'}
                                />
                              ) : (
                                'Send Reset Email'
                              )}
                            </button>
                          </div>
                          <div className="flex w-full lg:w-1/2">
                            <button
                              type="button"
                              className="underline mx-auto cursor-pointer text-sm"
                              onClick={() => setDisplay('login')}
                            >
                              Return to login
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
          </div>
        </div>
        <div className="bg-tradextra-application bg-center w-1/2 bg-cover hidden xs:flex flex-col justify-between p-5 lg:p-10 text-white relative">
          <div className="bg-black opacity-50 h-full w-full absolute top-0 bottom-0 left-0 right-0"></div>
          <div className="z-1 flex flex-col items-end">
            <button
              onClick={() => {
                onClose();
              }}
            >
              <img
                src="https://img-cdn.manutantraders.com/image/upload/v1719392556/etp/close_icon.png"
                className="w-10 h-10 mb-1"
                alt="close"
              />
              <p className="text-sm">Close</p>
            </button>
          </div>

          <div className="z-1">
            <h2 className="text-4xl lg:text-5xl mb-3 lg:mb-4">
              Trusted to <span className="text-orange">deliver.</span>
            </h2>
            <p className="mb-4">
              We’re on a journey to become the trades’ most trusted destination for buying
              ironmongery and associated products.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
