// import { useState } from 'react';
import HeaderBasketProductRow from './HeaderBasketProductRow';
// import Notification from './HeaderBasketNotification';
import Company from 'types/company';
import { Basket } from 'types/product';

interface basketTableProps {
  basketItems: Basket[];
  companyID: Company;
  basketId: number;
  showVat: boolean;
  updateBasketItem: (
    basketID: number,
    skuCode: string,
    quantity: number,
    basketItemId: number
  ) => void;
  removeBasketItem: any;
  // outOfStockSkus: any;
  handleClickReset: () => void;
}

const HeaderBasketTable = ({
  basketItems,
  basketId,
  updateBasketItem,
  removeBasketItem,
  showVat,
  // outOfStockSkus,
  companyID,
  handleClickReset,
}: basketTableProps): JSX.Element => {
  // const [active, setActive] = useState<boolean>(false);

  // const handleQtyChange = (bool: boolean): void => {
  //   setActive(bool);
  // };

  return (
    <div className="rhd-basket__table ">
      {/* <div className={`rhd-basket__table__body ${active ? 'active' : ''}`}> */}
      <div className="rhd-basket__table__body">
        {basketItems.map((basketEntry, index) => (
          <HeaderBasketProductRow
            index={index}
            key={basketEntry.id}
            basketID={basketId}
            basketEntry={basketEntry}
            // handleQtyChange={handleQtyChange}
            updateBasketItem={updateBasketItem}
            removeBasketItem={removeBasketItem}
            showVat={showVat}
            companyID={companyID}
            handleClickReset={handleClickReset}
          />
        ))}
      </div>
    </div>
  );
};

export default HeaderBasketTable;
