import PageWrapper from '@components/pageWrapper';
import Company from 'types/company';
import { useEffect, useState } from 'react';
import TelNumber from '@components/Global/TelNumber';
import InternalLinks from './components/InternalLinks';
import ManutanGroupLinks from './components/ManutanGroupLinks';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface footerProps {
  companyID: number;
}

function NavFooter({ companyID }: footerProps): JSX.Element {
  const router = useRouter();
  const [showInternalLinks, setShowInternalLinks] = useState<boolean>(false);
  const [showManutanGroupLinks, setShowManutanGroupLinks] = useState<boolean>(false);
  const [showTelNumber, setShowTelNumber] = useState<boolean>(false);
  const [withoutHeader, setWithoutHeader] = useState<boolean>(false);

  useEffect(() => {
    setShowTelNumber(true);
  }, []);

  useEffect(() => {
    if (
      router.pathname === '/account/login' ||
      router.asPath === '/account/resetpassword' ||
      router.asPath.includes('research')
    ) {
      setWithoutHeader(true);
    } else {
      setWithoutHeader(false);
    }
  }, [router.asPath, router.pathname]);

  if (withoutHeader) {
    return <></>;
  }

  return (
    <div>
      <footer
        className={`bg-black-light text-white overflow-hidden w-full block ${
          companyID !== Company.Ironmongery ? 'theme-ed' : 'theme-id'
        }`}
      >
        <PageWrapper>
          <>
            <div>
              <div className="px-0 py-30px 1192px:pb-4 1192px:pt-45px">
                <p className="text-26px leading-10 font-bold">
                  Trusted to{' '}
                  <span
                    className={companyID === Company.Ironmongery ? 'text-orange' : 'text-green'}
                  >
                    deliver
                  </span>
                </p>
              </div>
              <div className="flex flex-col justify-between 1192px:flex-row">
                <div className="navFooter">
                  <div className="hidden 1192px:flex">
                    <InternalLinks showInternalLinks={showInternalLinks} />
                  </div>
                  <div className="block 1192px:hidden">
                    <button
                      className={`flex justify-between font-bold text-18px leading-8 w-full items-center focus:outline-none ${
                        showInternalLinks ? 'pb-15px' : ''
                      }`}
                      onClick={() => setShowInternalLinks(!showInternalLinks)}
                    >
                      <span className="font-bold text-18px leading-8">Our Site</span>
                      <svg
                        className={`h-15px w-15px transition duration-200 fill-current transform ${
                          showInternalLinks ? 'rotate-90' : 'rotate-0'
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                      </svg>
                    </button>
                    <InternalLinks showInternalLinks={showInternalLinks} />
                  </div>

                  <div className="mt-0 mr-0 py-15px 1192px:mr-16 1192px:mt-6 1192px:py-0">
                    <div className="hidden 1192px:block">
                      <span className="text-grey-pitch font-bold text-22px leading-8 hidden 1192px:block">
                        Manutan Group
                      </span>
                      <ManutanGroupLinks showManutanGroupLinks={showManutanGroupLinks} />
                    </div>

                    <div className="block 1192px:hidden">
                      <button
                        className={`flex justify-between font-bold text-18px leading-8 w-full items-center focus:outline-none ${
                          showManutanGroupLinks ? 'pb-15px' : ''
                        }`}
                        onClick={() => setShowManutanGroupLinks((prev) => !prev)}
                      >
                        <span className="font-bold text-18px leading-8">Manutan Group</span>
                        <svg
                          className={`h-15px w-15px transition duration-200 fill-current transform ${
                            showManutanGroupLinks ? 'rotate-90' : 'rotate-0'
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                        </svg>
                      </button>
                      <ManutanGroupLinks showManutanGroupLinks={showManutanGroupLinks} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-0 mr-0 py-7/50 border-solid border-t-1 border-black-order-sum-b 1192px:mr-16 1192px:mt-6 1192px:py-0 1192px:border-t-0">
                  <span className="text-grey-pitch font-bold text-22px leading-8 hidden 1192px:block">
                    Contact Us
                  </span>
                  {showTelNumber && (
                    <span className="mt-0 mr-0 1192px:mr-4 1192px:mt-6 font-bold hover:underline hover:text-current">
                      <TelNumber />
                    </span>
                  )}
                  <a
                    href={
                      companyID !== 1
                        ? 'mailto:sales@electricaldirect.co.uk'
                        : 'mailto:sales@ironmongerydirect.com'
                    }
                    className="mt-6 mr-0 1192px:mr-4 font-bold hover:underline hover: text-current"
                  >
                    {companyID !== 1
                      ? 'sales@electricaldirect.co.uk'
                      : 'sales@ironmongerydirect.com'}
                  </a>
                  <ul className="flex flex-row mt-5">
                    <li>
                      <a
                        href={
                          companyID !== 1
                            ? 'https://www.facebook.com/ElectricalDct'
                            : 'https://www.facebook.com/IronmongeryDirect'
                        }
                        title="Like us on Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-0 mr-7/50 1192px:mb-7  w-8 h-8 bg-black-product-notice-b flex items-center justify-center rounded-full transition duration-200 hover:bg-grey-pitch"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          className="h-4.5 w-4.5 text-white fill-current"
                        >
                          <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          companyID !== 1
                            ? 'https://twitter.com/ElectricalDct'
                            : 'https://twitter.com/IronmngryDirect'
                        }
                        title="Follow us on X"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-0 mr-7/50 1192px:mb-7 w-8 h-8 bg-black-product-notice-b flex items-center justify-center rounded-full transition duration-200 hover:bg-grey-pitch"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="h-4.5 w-4.5 text-white fill-current"
                        >
                          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          companyID !== 1
                            ? 'https://www.instagram.com/electricaldirectuk'
                            : 'https://www.instagram.com/ironmongerydirectuk/'
                        }
                        title="Visit us on Instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-0 mr-7/50 1192px:mb-7 w-8 h-8 bg-black-product-notice-b flex items-center justify-center rounded-full transition duration-200 hover:bg-grey-pitch"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="h-4.5 w-4.5 text-white fill-current"
                        >
                          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          companyID !== 1
                            ? 'https://www.linkedin.com/company/electrical-direct'
                            : 'https://www.linkedin.com/company/ironmongery-direct-ltd'
                        }
                        title="Visit us on LinkedIn"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mb-0 mr-7/50 1192px:mb-7 w-8 h-8 bg-black-product-notice-b flex items-center justify-center rounded-full transition duration-200 hover:bg-grey-pitch"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          className="h-4.5 w-4.5 text-white fill-current"
                        >
                          <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="py-6/25 px-0 flex flex-row justify-between items-center border-t-1 border-solid border-black-order-sum-b md:py-30px 1192px:py-40px">
              <div className="h-8 w-32 hidden 1192px:block">
                <img
                  className=""
                  src={companyID !== 1 ? '/images/ED-Dark-bg.svg' : '/images/ID-Dark-bg.svg'}
                  alt="IronmongeryDirect"
                />
              </div>
              <div className="flex items-start flex-col md:items-center md:flex-row">
                <div className="flex items-center mb-3.5 md:mb-0">
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/c_scale,w_100,q_auto,f_auto/shared/rebrand/logos/accepted-cards/Visa.png"
                    className="mr-8"
                    width="30"
                    height="11"
                    alt="VISA"
                    title="VISA"
                  />
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/c_scale,w_100,q_auto,f_auto/shared/rebrand/logos/accepted-cards/AMex.png"
                    className="w-10 h-3 md:w-12 md:h-3 1192px:w-14 1192px:h-15px mr-8"
                    width="30"
                    height="11"
                    alt="American Express"
                    title="American Express"
                  />
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/c_scale,w_150,q_auto,f_auto/shared/rebrand/logos/accepted-cards/Mastercard.png"
                    className="w-14 h-3 md:w-20 md:h-3.5 1192px:w-24 1192px:h-4.5 mr-8"
                    width="30"
                    height="11"
                    alt="Mastercard"
                    title="Mastercard"
                  />
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/c_scale,w_150,q_auto,f_auto/shared/rebrand/logos/accepted-cards/Maestro.png"
                    className="w-14 h-3 md:w-16 md:h-3.5 1192px:w-20 1192px:h-4.5 mr-8"
                    width="30"
                    height="11"
                    alt="Maestro"
                    title="Maestro"
                  />
                </div>
                <div className="flex items-center">
                  <img
                    src="/images/iso_logo.png"
                    className="mr-8"
                    alt="ISO Grade Certificate"
                    title="ISO Grade Certificate"
                    width="40"
                    height="20"
                  />
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/w_100,c_scale/v1720163346/shared/rebrand/logos/GAI-Corporate-Member-logo-ALL-COLOUR.png"
                    className="mr-8"
                    alt="GAI"
                    title="GAI"
                    width="40"
                    height="20"
                  />
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/w_100,c_scale/v1720163225/shared/rebrand/logos/adyen-logo.png"
                    className="mr-8"
                    alt="Adyen"
                    title="Adyen"
                    width="40"
                    height="20"
                  />
                  <img
                    src="https://img-cdn.manutantraders.com/image/upload/c_scale,w_100,q_auto,f_auto/v1673262560/electrical/UK_DEC_22_23_CERTIFIED_LOGO.png"
                    className="mr-8"
                    alt="UK Great Place to Work"
                    title="Voted one of the UK's Greatest Workplaces"
                    width="20"
                    height="20"
                  />
                  {companyID === 1 && (
                    <>
                      <img
                        src="https://img-cdn.manutantraders.com/image/upload/c_scale,w_100,q_auto,f_auto/ironmongery/In_House_Research_2022_Outstanding_Achievement_2022.png"
                        className="mr-4 1192px:mr-8"
                        alt="In-house Research"
                        title="2022 Outstanding Award for Customer Satisfaction"
                        width="40"
                        height="20"
                      />
                    </>
                  )}
                  <a
                    href="https://recognition.ecovadis.com/Fl5UFF-l5kuCQI0I_G2AVQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img-cdn.manutantraders.com/image/upload/w_100,c_scale/v1721893963/ecovadis.png"
                      className="mr-8"
                      alt="Ecovadis medal"
                      title="Ecovadis medal"
                      width="30"
                      height="30"
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        </PageWrapper>
      </footer>
      <footer
        className={`bg-blackBG pt-6 pb-5 px-0 text-white w-full block ${
          companyID !== Company.Ironmongery ? 'theme-ed' : 'theme-id'
        }`}
      >
        <PageWrapper>
          <>
            <div className="h-8 w-32 mb-2 block 1192px:hidden">
              <img
                className="h-6 w-24/25"
                src={
                  companyID !== Company.Ironmongery
                    ? '/images/ED-Dark-bg.svg'
                    : '/images/ID-Dark-bg.svg'
                }
                alt={companyID !== Company.Ironmongery ? 'ElectricalDirect' : 'IronmongeryDirect'}
              />
            </div>
            <p className="text-14px">
              ©{' '}
              {`${new Date().getFullYear()} ${
                companyID !== 1 ? 'ElectricalDirect' : 'IronmongeryDirect'
              }`}{' '}
              Limited. All Rights reserved. E&amp;OE.{' '}
              <Link href="/information/terms">
                <a
                  className="hover:text-current hover:underline"
                  title="See our Terms &amp; Conditions"
                >
                  Terms &amp; Conditions{' '}
                </a>
              </Link>
              apply. See our{' '}
              <Link href="/information/privacy">
                <a className="hover:text-current hover:underline" title="See our Privacy Policy">
                  {' '}
                  Privacy Policy
                </a>
              </Link>
              .
            </p>
          </>
        </PageWrapper>
      </footer>
    </div>
  );
}

export default NavFooter;
