import { DYContext } from '../../types/dynamicYield';

declare const DY: any;

const setDYContext = (type: DYContext, data?: string[]): void => {
  window.DY = window.DY || {};

  if (type === DYContext.Homepage || type === DYContext.Other) {
    DY.recommendationContext = { type };
  } else {
    DY.recommendationContext = { type, data };
  }

  // active user consent
  DY.userActiveConsent = { accepted: true };
};

export { setDYContext };
