/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';
import { useState } from 'react';
import Company from 'types/company';

interface contactProps {
  companyID: Company;
  handleClickReset: () => void;
}

function HeaderContact({ companyID, handleClickReset }: contactProps): JSX.Element {
  const [isCallUsClicked, setIsCallUsClicked] = useState<boolean>(false);
  return (
    <div className="flex flex-col" id="contact-aside">
      <div className="flex items-center text-white">
        <svg
          className="mr-4 w-5 h-5 align-middle overflow-visible fill-current 1192px:w-6 1192px:h-6 xl:mr-4.5"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.32.709c-.151-.03-.334-.03-.456.03-1.643.64-2.677 2.99-2.92 4.607-.761 5.248 3.377 9.794 7.18 12.784 3.377 2.654 9.859 7.017 13.692 2.806.487-.518 1.065-1.28 1.035-2.044-.091-1.281-1.278-2.196-2.19-2.898-.7-.519-2.161-1.953-3.074-1.922-.822.03-1.339.885-1.856 1.403l-.913.916c-.152.152-2.1-1.13-2.312-1.282a15.533 15.533 0 01-2.222-1.739 14.374 14.374 0 01-1.795-2.075c-.152-.213-1.4-2.074-1.278-2.227 0 0 1.065-1.16 1.37-1.617.638-.976 1.125-1.739.395-2.898-.274-.427-.609-.763-.974-1.129-.639-.61-1.278-1.25-2.008-1.77C5.6 1.35 4.93.8 4.321.71z"
            fill="currentcolor"
            fillRule="nonzero"
          ></path>
        </svg>
        <p className="text-xl leading-34px font-bold 1192px:text-2xl 1192px:leading-40px">
          Contact Us
        </p>
      </div>
      <div className="flex flex-col py-30px px-0">
        <button
          id="call-us"
          data-tracking="contact-call-us"
          className="text-white rounded-lg mb-6 focus:outline-none"
          title="Call Us"
          onClick={() => {
            setIsCallUsClicked(!isCallUsClicked);
          }}
        >
          <div
            className={`flex justify-between items-center py-2 px-0 ${
              companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
            }`}
          >
            <p className="text-18px leading-relaxed md:text-20px">Call Us</p>
            <svg
              className={`inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3 ${
                isCallUsClicked ? 'rotate-90' : 'rotate-0'
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
            </svg>
          </div>
          <div
            className={`transition-all duration-200 ${
              isCallUsClicked ? 'max-h-full opacity-100 visible' : 'max-h-0 opacity-0 invisible'
            }`}
            id="call-info"
          >
            <p className="font-bold text-20px leading-loose mb-2 text-left text-orange">
              <a href="tel:0300 303 88 21" className="hover:underline hover:text-orange">
                0300 303 88 21
              </a>
            </p>
            <p className="font-semibold text-18px leading-none mb-0 text-left text-orange">
              Mon-Fri 7am-7pm & Sat 9am-1pm
            </p>
          </div>
        </button>
        <Link href="/contact">
          <a
            data-tracking="contact-email-us"
            title="Email Us"
            className="text-white rounded-lg mb-6"
            role="button"
            tabIndex={0}
            onClick={() => {
              handleClickReset();
            }}
          >
            <div
              className={`flex justify-between items-center py-2 px-0 ${
                companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
              }`}
            >
              <p className="text-18px leading-relaxed md:text-20px">Email Us</p>
              <svg
                className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
              </svg>
            </div>
          </a>
        </Link>
        {/* <div
          role="button"
          // href="/contact"
          data-tracking="contact-live-chat"
          className="text-white rounded-lg mb-6"
          title="Live Chat"
        >
          <div
            className={`flex justify-between items-center py-2 px-0 ${
              companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
            }`}
          >
            <p className="text-18px leading-relaxed md:text-20px">Live Chat</p>
            <svg
              className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
            </svg>
          </div>
        </div> */}
        <Link href="/track">
          <a
            data-tracking="contact-tracking-chat"
            title="Track Your Order"
            className="text-white rounded-lg mb-6"
            role="button"
            tabIndex={0}
            onClick={handleClickReset}
          >
            <div
              className={`flex justify-between items-center py-2 px-0 ${
                companyID === Company.Ironmongery ? 'hover:text-orange' : 'hover:text-green'
              }`}
            >
              <p className="text-18px leading-relaxed md:text-20px">Track Your Order</p>
              <svg
                className="inline-block h-5 w-5 transition-all transform duration-200 align-middle overflow-visible fill-current md:h-6 md:w-6 1192px:w-3 1192px:h-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
              </svg>
            </div>
          </a>
        </Link>
      </div>
    </div>
  );
}

export default HeaderContact;
