export const processTown = (s1: string, s2: string): string => {
  let result = s2;

  if (s1.length > 0 && s2.length > 0) {
    result = `${s1}, ${s2}`;
  }

  if (s1.length > 0 && s2.length === 0) {
    result = s1;
  }

  return result;
};

// export const processStreet = (s1: string, s2: string, s3: string): string => {
//   let result = s2;

//   if (s1.length > 0 && s2.length > 0 && s3.length > 0) {
//     result = `${s1} ${s2}, ${s3}`;
//   }

//   if (s1.length > 0 && s2.length === 0 && s3.length > 0) {
//     result = `${s1} ${s3}`;
//   }

//   if (s1.length > 0 && s2.length > 0 && s3.length === 0) {
//     result = `${s1} ${s2}`;
//   }

//   if (s1.length === 0 && s2.length > 0 && s3.length > 0) {
//     result = `${s2}, ${s3}`;
//   }

//   if (s1.length === 0 && s2.length == 0 && s3.length > 0) {
//     result = `${s3}`;
//   }

//   return result;
// };

export const processStreet = (s1: string, s2: string): string => {
  let result = s2;

  if (s1.length > 0 && s2.length > 0) {
    result = `${s1} ${s2}`;
  }

  if (s1.length > 0 && s2.length === 0) {
    result = `${s1}`;
  }

  if (s1.length > 0 && s2.length > 0) {
    result = `${s1} ${s2}`;
  }

  if (s1.length === 0 && s2.length > 0) {
    result = `${s2}`;
  }

  return result;
};

export const processBuilding = (s1: string, s2: string): string => {
  let result = s2;

  if (s1.length > 0 && s2.length > 0) {
    result = `${s1}, ${s2}`;
  }

  if (s1.length > 0 && s2.length === 0) {
    result = s1;
  }

  return result;
};
