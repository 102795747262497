import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { GeoLocationStatus } from '@hooks/useGeolocation';
import PostcodeInput from './postcode-input';

interface CollectForm {
  postcode: string;
}

interface PostcodeFormProps {
  handleFormSubmit: (postcode: string) => void;
  getLocation: () => void;
  geolocationStatus: GeoLocationStatus | null;
  fetchError: string;
  postcode: string;
  noFindLocation?: boolean;
  noPaddingBtm?: boolean;
}

const PostcodeForm = ({
  handleFormSubmit,
  getLocation,
  geolocationStatus,
  fetchError,
  postcode,
  noFindLocation,
  noPaddingBtm,
}: PostcodeFormProps): React.ReactElement => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        postcode,
      }}
      onSubmit={(values: CollectForm) => {
        handleFormSubmit(values.postcode);
      }}
      validationSchema={Yup.object().shape({
        postcode: Yup.string()
          .matches(
            /^(([A-Z]{1,2}\d[A-Z\d]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?\d[A-Z]{2}|BFPO ?\d{1,4}|(KY\d|MSR|VG|AI)[ -]?\d{4}|[A-Z]{2} ?\d{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i,
            'Please enter a valid UK postcode. '
          )
          .required('Please provide a postcode.'),
      })}
    >
      {(props: FormikProps<CollectForm>) => {
        const { submitForm } = props;

        return (
          <Form className="w-full flex flex-col">
            <div
              className={noPaddingBtm ? '' : 'border-b-1 border-solid border-grey-concrete pb-7/50'}
            >
              <div className="flex flex-col mt-11 md:flex-row">
                <div className="w-full">
                  <PostcodeInput
                    name="postcode"
                    type="text"
                    label="Enter your postcode"
                    buttonType="button"
                    buttonOnClick={submitForm}
                    InputKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        submitForm();
                      }
                    }}
                    noFindLocation={noFindLocation}
                    getLocation={getLocation}
                    geolocationStatus={geolocationStatus}
                  />
                </div>
              </div>
              {fetchError ? <p className="text-xs text-red mt-1">{fetchError}</p> : null}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PostcodeForm;
