interface Content {
  id: string;
  quantity: number;
  itemPrice: number;
}

export interface FbEventData {
  eventId?: string;
  eventName: FbEventName;
  eventSourceUrl: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  status?: 'successful' | 'failed'; // for COMPLETE_REGISTRATION event only
  contentName?: string;
  contentCategory?: string;
  contentIds?: string[];
  contentType?: 'product' | 'product_group';
  value?: number;
  numItems?: string; // for INITIATE_CHECKOUT event only
  contents?: Content[];
}

export enum FbEventName {
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WISHLIST = 'AddToWishlist',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  SUBMIT_APPLICATION = 'SubmitApplication',
}
