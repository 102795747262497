// To parse this data:
//
//   import { Convert } from "./file";
//
//   const addressResponse = Convert.toAddressResponse(json);

export interface AddressResponse {
  id: string;
  customerId: string;
  addressErrors?: string[];
  name: string;
  lineOne: string;
  lineTwo: string;
  lineThree: null | string;
  lineFour?: string;
  lineFive?: null | string;
  postCode: string;
  county: null | string;
  country: Country;
  default?: boolean;
  visible: boolean;
  isBilling?: boolean;
  postTown?: null | string;
  isValid?: boolean;
  reference?: null | string;
  houseName?: string;
}

export type CreateAddress = Omit<AddressResponse, 'id' | 'addressErrors' | 'customerId'>;

export enum Country {
  GB = 'GB',
}

export interface AddressForm {
  postcode: string;
  street: string;
  building?: string;
  city: string;
  county: string;
  reference?: string;
  EORI?: string;
  default?: boolean;
}

// Converts JSON strings to/from your types
export class ConvertAddress {
  public static toAddressResponse(json: string): AddressResponse[] {
    return JSON.parse(json);
  }

  public static addressResponseToJson(value: AddressResponse[]): string {
    return JSON.stringify(value);
  }
}
