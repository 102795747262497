import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from 'src/redux/store';
import { SortedCollect, SortedDelivery } from 'types/sorted';
import axios, { AxiosError } from 'axios';

export interface SortedState {
  loading: 'idle' | 'pending';
  currentRequestId: null | string;
  error: null | string;
  sortedDelivery: SortedDelivery | null;
  sortedCollect: SortedCollect | null;
}

export type SortedDeliverySelect = Omit<SortedState, 'currentRequestId' | 'sortedCollect'>;
export type SortedCollectSelect = Omit<SortedState, 'currentRequestId' | 'sortedDelivery'>;

const initialState: SortedState = {
  loading: 'idle',
  currentRequestId: null,
  error: null,
  sortedDelivery: null,
  sortedCollect: null,
};

const hydrate = createAction<RootState>(HYDRATE);

export const getSortedDelivery = createAsyncThunk<
  SortedDelivery,
  { addressID: string; token: string },
  {
    state: RootState;
    rejectValue: 'failed' | 'fetching';
  }
>('sorted/getSortedDelivery', async (payload, thunkAPI) => {
  try {
    const { sorted } = thunkAPI.getState() as { sorted: SortedState };
    if (sorted.loading !== 'pending' || thunkAPI.requestId !== sorted.currentRequestId) {
      return thunkAPI.rejectWithValue('fetching');
    }
    const { status, data }: { status: number; data: { sortedData: SortedDelivery } } =
      await axios.post('/api/getByAddress', {
        addressID: payload.addressID,
        token: payload.token,
      });

    if (status === 200) {
      return data.sortedData;
    } else {
      throw 'No content';
    }
  } catch (err: any) {
    if (err === 'No content') {
      return thunkAPI.rejectWithValue('failed');
    }
    const error: AxiosError = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue('failed');
  }
});

export const getSortedCollect = createAsyncThunk<
  SortedCollect,
  { postcode: string; token: string },
  {
    state: RootState;
    rejectValue: 'failed' | 'fetching';
  }
>('sorted/getSortedCollect', async (payload, thunkAPI) => {
  try {
    const { sorted } = thunkAPI.getState() as { sorted: SortedState };
    if (sorted.loading !== 'pending' || thunkAPI.requestId !== sorted.currentRequestId) {
      return thunkAPI.rejectWithValue('fetching');
    }
    const { status, data }: { status: number; data: { sortedData: SortedCollect } } =
      await axios.post('/api/getByPostcode', {
        postcode: payload.postcode,
        token: payload.token,
      });

    if (status === 200) {
      return data.sortedData;
    } else {
      throw 'No content';
    }
  } catch (err: any) {
    if (err === 'No content') {
      return thunkAPI.rejectWithValue('failed');
    }
    const error: AxiosError = err;
    if (!error.response) {
      throw err;
    }
    return thunkAPI.rejectWithValue('failed');
  }
});

export const sortedSlice = createSlice({
  name: 'sorted',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload[sortedSlice.name],
      };
    });
    builder.addCase(getSortedDelivery.rejected, (state, action) => {
      if (action.payload === 'failed') {
        state.sortedDelivery = null;
        state.loading = 'idle';
        state.currentRequestId = null;
        state.error =
          'We were unable to get delivery options for your address, please try again or choose a different address.';
      }
    });
    builder.addCase(getSortedDelivery.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.sortedDelivery = null;
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
        state.error = null;
      }
    });
    builder.addCase(getSortedDelivery.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.sortedDelivery = action.payload;
        state.currentRequestId = null;
      }
    });
    builder.addCase(getSortedCollect.rejected, (state, action) => {
      if (action.payload === 'failed') {
        state.sortedCollect = null;
        state.loading = 'idle';
        state.currentRequestId = null;
        state.error =
          'We were unable to get collect options for your postcode, please try again or try a different postcode.';
      }
    });
    builder.addCase(getSortedCollect.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.sortedCollect = null;
        state.loading = 'pending';
        state.currentRequestId = action.meta.requestId;
        state.error = null;
      }
    });
    builder.addCase(getSortedCollect.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.sortedCollect = action.payload;
        state.currentRequestId = null;
      }
    });
  },
});

export const selectSortedDelivery = (state: RootState): SortedDeliverySelect => {
  return {
    sortedDelivery: state.sorted.sortedDelivery,
    loading: state.sorted.loading,
    error: state.sorted.error,
  };
};

export const selectSortedCollect = (state: RootState): SortedCollectSelect => {
  return {
    sortedCollect: state.sorted.sortedCollect,
    loading: state.sorted.loading,
    error: state.sorted.error,
  };
};
export default sortedSlice.reducer;
