import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Company from 'types/company';

interface setBrandProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
}

const initialState = {
  productCategory: [],
  isFetchingData: false,
};

export const setProductCategories = createAsyncThunk(
  'category/setProductCategories',
  async (param: setBrandProps) => {
    const { companyID, baseUrl, apiKey } = param;
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/product/Category/Tree?companyId=${companyID}`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
      },
    });
    return response.data;
  }
);

export const categorySlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setProductCategories.pending, (state) => {
        // Add user to the state array
        state.isFetchingData = true;
      })
      .addCase(setProductCategories.fulfilled, (state, action) => {
        // Add user to the state array
        state.productCategory = action.payload;
        state.isFetchingData = false;
      });
  },
});

export default categorySlice.reducer;
