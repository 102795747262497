export const AUTH_GATEWAY = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/checkout/api/Auth`;
export const ADDRESS_GATEWAY = `${process.env
  .NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/checkout/api/Addresses`;
export const ORDER_SUMMARY_GATEWAY = `${process.env
  .NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/checkout/api/Orders`;
export const SORTED_GATEWAY = `${process.env
  .NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/checkout/api/DeliveryOptions`;
export const ACCOUNT_GATEWAY = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/account`;
export const PRODUCT_GATEWAY = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/product`;
export const METADATA_GATEWAY = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/metadata`;

// Front end
export const COMPANY_ID_FE = `${process.env.NEXT_PUBLIC_COMPANY_ID!}`;
export const MANUTAN_GATEWAY_URL_FE = process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!;
export const API_KEY_FE = process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY!;
export const MAPBOX_ACCESS_TOKEN_FE = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN!;

export const PRODUCT_GATEWAY_FE = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/product`;
export const ACCOUNT_GATEWAY_FE = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/account`;
export const METADATA_GATEWAY_FE = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL!}/metadata`;
