import Company from 'types/company';

export const checkoutLink = (
  companyID: Company,
  details: {
    UserName: string;
    LastVisited: string;
    ActiveCompany: string;
    CardCode: string;
    IDLBasket: string;
  }
): string => {
  const isDevelop = process.env.NEXT_PUBLIC_APP_ENV === 'development';
  const isStaging = process.env.NEXT_PUBLIC_APP_ENV === 'staging';

  if (isDevelop) {
    return `https://securedev.${
      companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
    }direct.co.uk/newcheckout/setCookies?UserName=${details.UserName}&LastVisited=${
      details.LastVisited
    }&ActiveCompany=${details.ActiveCompany}&CardCode=${details.CardCode}&basketId=${
      details.IDLBasket
    }`;
  }

  if (isStaging) {
    return `https://pay.${
      companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
    }direct.co.uk/newcheckout/setCookies?UserName=${details.UserName}&LastVisited=${
      details.LastVisited
    }&ActiveCompany=${details.ActiveCompany}&CardCode=${details.CardCode}&basketId=${
      details.IDLBasket
    }`;
  }

  return `https://pay.${
    companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'
  }direct.co.uk`;
};
