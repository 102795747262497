import Company from 'types/company';

interface notificationProps {
  notificationType: string;
  expectedDeliveryDate?: string | number | Date;
  companyID: Company;
  leadTimeInfo?: string;
  stockLevel?: number | boolean;
  quantity?: number;
}

const Notification = ({
  notificationType,
  expectedDeliveryDate,
  companyID,
  leadTimeInfo,
  stockLevel,
  quantity,
}: notificationProps): JSX.Element => {
  const getNotificationTitleIcon = (type: string): string => {
    let icon = '';
    switch (type) {
      case 'oos':
        icon = 'question';
        break;
      default:
        break;
    }
    return icon;
  };

  const getNotificationTitle = (type: string): string => {
    let title = '';
    switch (type) {
      // item is out of stock without expected delivery date
      case 'oos-1':
        title = 'This item is now out of stock';
        break;
      // item is out of stock with expected delivery date
      case 'oos-2':
        title = `This item will be delivered on ${new Date(expectedDeliveryDate).toLocaleDateString(
          'en-gb'
        )}`;
        break;
      case 'direct':
        title = 'The item is delivered direct from the supplier';
        break;
      case 'hazard':
        title = 'Hazardous item - Delivery within 2 days';
        break;
      case 'long':
        title = 'Large Item*';
        break;
      case 'backorder':
        title = `This delivery will contain ${stockLevel} out of the ${quantity} items`;
        break;
      default:
        title = '';
        break;
    }
    return title;
  };

  const getNotificationBody = (type: string): string => {
    let body = '';
    switch (type) {
      case 'direct':
        body = `You can expect delivery within ${
          leadTimeInfo && leadTimeInfo !== '0' ? leadTimeInfo : '2-3'
        } days`;
        break;
      case 'hazard':
        body = 'Delivery restrictions may also apply for non-mainland UK customers';
        break;
      case 'backorder':
        body = `The remaining items are expected to be delivered on ${new Date(
          expectedDeliveryDate
        ).toLocaleDateString('en-gb')}`;
        break;
      default:
        body = '';
        break;
    }
    return body;
  };

  return (
    <div className="rhd-aside__input__validation rhd-aside__input__validation--basket active">
      <p
        className={`rhd-aside__input__validation__title ${
          companyID === Company.Ironmongery
            ? 'rhd-aside__input__validation__title--id'
            : 'rhd-aside__input__validation__title--ed'
        } ${notificationType === 'backorder' ? 'backorder' : ''} ${getNotificationTitleIcon(
          notificationType
        )}`}
      >
        {getNotificationTitle(notificationType)}
      </p>
      <p
        className={`rhd-aside__input__validation__body ${
          notificationType === 'long' ? 'd-none' : ''
        }`}
      >
        {getNotificationBody(notificationType)}
      </p>
    </div>
  );
};
export default Notification;
