import {
  createSlice,
  createAsyncThunk,
  // PayloadAction
} from '@reduxjs/toolkit';
import { Basket } from 'types/product';
import axios from 'axios';
import Company from 'types/company';
interface getBasketParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  token: string;
}

interface removeBasketParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  basketId: number;
  accountCode: string;
  emailAddress: string;
  skuCode: string;
  basketItemId: number;
  token: string;
}

interface removeOutOfStockParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  basketId: number;
  skuCode: string;
  accountCode: string;
  emailAddress: string;
  token: string;
}

interface addBasketParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  basketId: number;
  accountCode: string;
  emailAddress: string;
  skuCode: number;
  quantity: number;
  length: number | null;
  width: number | null;
  screwholes: boolean | null;
  calculatedPrice: number | null;
  token: string;
}

interface updateBasketParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  basketId: number;
  accountCode: string;
  emailAddress: string;
  skuCode: string;
  quantity: number;
  basketItemId: number;
  token: string;
}

interface basketProps {
  basketId: number;
  basketTotal: number;
  basketItems: Basket[];
  outOfStockSkus: any;
  basketEmpty: boolean;
  isLoading: boolean;
  isLoadingUpdate: boolean;
  isBasketLoading: boolean;
  isAdded: boolean;
  hasError: boolean;
  pageInitial: boolean;
  hasUpdate: boolean;
}

interface addPreviousOrderBaskeParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  accountCode: string;
  emailAddress: string;
  orderId: number;
  basketId: number;
  token: string;
}

interface mergeBasketParamProps {
  companyID: Company;
  baseUrl: string | undefined;
  apiKey: string | undefined;
  accountCode: string;
  emailAddress: string;
  basketId: number;
  guestBasketItems: Basket[];
  token: string;
}

const initialState: basketProps = {
  basketId: 0,
  basketTotal: 0,
  basketItems: [],
  outOfStockSkus: [],
  basketEmpty: false,
  isLoading: false,
  isLoadingUpdate: false,
  isBasketLoading: true,
  isAdded: false,
  hasError: false,
  pageInitial: false,
  hasUpdate: false,
};

export const getBasket = createAsyncThunk(
  'basket/getBasket',
  async (param: getBasketParamProps) => {
    const { baseUrl, apiKey, token } = param;
    return await axios({
      method: 'GET',
      url: `${baseUrl}/basket/GetExistingBasket`,
      headers: {
        'ocp-apim-subscription-key': apiKey,
        Authorization: token,
      },
    }).then((response) => {
      return response.data;
    });
  }
);

export const removeBasketItem = createAsyncThunk(
  'basket/removeBasketItem',
  async (param: removeBasketParamProps) => {
    const {
      // companyID,
      baseUrl,
      apiKey,
      basketId,
      accountCode,
      emailAddress,
      skuCode,
      basketItemId,
      token,
    } = param;
    return await axios({
      method: 'POST',
      url: `${baseUrl}/basket/RemoveItem`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
      },
      data: {
        basketId,
        accountCode: String(accountCode),
        emailAddress,
        skuCode: Number(skuCode),
        basketItemId,
      },
    })
      .then(() => {
        return axios({
          method: 'POST',
          url: `${baseUrl}/basket/MergeBasket`,
          headers: {
            // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
            Authorization: token,
            'ocp-apim-subscription-key': apiKey,
          },
          data: {
            basketId,
            accountCode: String(accountCode),
            emailAddress,
          },
        }).then((response) => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
);

export const removeOutOfStockBasketItem = createAsyncThunk(
  'basket/RemoveOutOfStockBasketItem',
  async (param: removeOutOfStockParamProps) => {
    const { baseUrl, apiKey, basketId, accountCode, emailAddress, skuCode, token } = param;
    return await axios({
      method: 'POST',
      url: `${baseUrl}/basket/RemoveOutOfStockBasketItem`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
      },
      data: {
        basketId,
        skuCode,
      },
    })
      .then(() => {
        return axios({
          method: 'POST',
          url: `${baseUrl}/basket/MergeBasket`,
          headers: {
            // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
            Authorization: token,
            'ocp-apim-subscription-key': apiKey,
          },
          data: {
            basketId,
            accountCode: String(accountCode),
            emailAddress,
          },
        }).then((response) => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
);

export const addBasketItem = createAsyncThunk(
  'basket/addBasketItem',
  async (param: addBasketParamProps) => {
    const {
      // companyID,
      baseUrl,
      apiKey,
      basketId,
      accountCode,
      emailAddress,
      skuCode,
      quantity,
      length,
      width,
      screwholes,
      calculatedPrice,
      token,
    } = param;
    return await axios({
      method: 'POST',
      url: `${baseUrl}/basket/AddItem`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
        Authorization: token,
      },
      data: {
        basketId,
        accountCode: String(accountCode),
        emailAddress,
        skuCode: Number(skuCode),
        quantity,
        length,
        width,
        screwholes,
        calculatedPrice,
      },
    })
      .then(() => {
        return axios({
          method: 'POST',
          url: `${baseUrl}/basket/MergeBasket`,
          headers: {
            // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
            Authorization: token,
            'ocp-apim-subscription-key': apiKey,
          },
          data: {
            basketId,
            accountCode: String(accountCode),
            emailAddress,
          },
        }).then((response) => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
);

export const updateBasketItem = createAsyncThunk(
  'basket/updateBasketItem',
  async (param: updateBasketParamProps) => {
    const {
      // companyID,
      baseUrl,
      apiKey,
      basketId,
      accountCode,
      emailAddress,
      skuCode,
      quantity,
      basketItemId,
      token,
    } = param;
    return await axios({
      method: 'POST',
      url: `${baseUrl}/basket/UpdateItem`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
      },
      data: {
        basketId,
        accountCode: String(accountCode),
        emailAddress,
        skuCode: Number(skuCode),
        Quantity: quantity,
        basketItemId,
      },
    })
      .then(() => {
        return axios({
          method: 'POST',
          url: `${baseUrl}/basket/MergeBasket`,
          headers: {
            // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
            Authorization: token,
            'ocp-apim-subscription-key': apiKey,
          },
          data: {
            basketId,
            accountCode: String(accountCode),
            emailAddress,
          },
        }).then((response) => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
);

export const addPreviousOrderBasketItem = createAsyncThunk(
  'basket/addPreviousOrderBasketItem',
  async (param: addPreviousOrderBaskeParamProps) => {
    const { baseUrl, apiKey, accountCode, emailAddress, orderId, basketId, token } = param;
    return await axios({
      method: 'POST',
      url: `${baseUrl}/basket/AddPreviousOrderToBasket`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
      },
      data: {
        accountCode: String(accountCode),
        emailAddress,
        orderId,
      },
    })
      .then(() => {
        return axios({
          method: 'POST',
          url: `${baseUrl}/basket/MergeBasket`,
          headers: {
            // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
            Authorization: token,
            'ocp-apim-subscription-key': apiKey,
          },
          data: {
            basketId,
            accountCode: String(accountCode),
            emailAddress,
          },
        }).then((response) => response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
);

export const mergeGuestItemsToBasket = createAsyncThunk(
  'basket/mergeGuestItemsToBasket',
  async (param: mergeBasketParamProps) => {
    const { baseUrl, apiKey, basketId, accountCode, emailAddress, guestBasketItems, token } = param;

    const skus = guestBasketItems.map((basketItem) => {
      const isMadeToMeasure =
        basketItem.m2mHeight !== null &&
        basketItem.m2mScrewholes !== null &&
        basketItem.m2mWidth !== null;

      return {
        skuCode: Number(basketItem.skuCode),
        quantity: basketItem.quantity,
        length: basketItem.m2mHeight === 0 ? null : basketItem.m2mHeight,
        width: basketItem.m2mWidth === 0 ? null : basketItem.m2mWidth,
        screwholes: basketItem.m2mScrewholes,
        calculatedPrice: isMadeToMeasure
          ? (basketItem.m2mWidth / 1000) * (basketItem.m2mHeight / 1000) * basketItem.prices.price
          : null,
      };
    });

    return await axios({
      method: 'POST',
      url: `${baseUrl}/basket/BulkAddItem`,
      headers: {
        Authorization: token,
        'ocp-apim-subscription-key': apiKey,
      },
      data: {
        basketId,
        accountCode: String(accountCode),
        emailAddress,
        skuCodes: skus,
      },
    })
      .then(() => {
        localStorage.removeItem('guestBasket');
        localStorage.removeItem('increasinglyBasket');
        return axios({
          method: 'POST',
          url: `${baseUrl}/basket/MergeBasket`,
          headers: {
            Authorization: token,
            'ocp-apim-subscription-key': apiKey,
          },
          data: {
            basketId,
            accountCode: String(accountCode),
            emailAddress,
          },
        }).then((response) => {
          return response.data;
        });
      })
      .catch(() => {
        localStorage.removeItem('guestBasket');
        localStorage.removeItem('increasinglyBasket');
        // return axios({
        //   method: 'POST',
        //   url: `${baseUrl}/basket/MergeBasket`,
        //   headers: {
        //     // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        //     'ocp-apim-subscription-key': apiKey,
        //   },
        //   data: {
        //     basketId,
        //     accountCode: String(accountCode),
        //     emailAddress,
        //   },
        // }).then((response) => {
        //   localStorage.removeItem('guestBasket');
        //   return response.data;
        // });
      });
  }
);

const BasketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setGuestBasket: (state, action) => {
      state.basketTotal = action.payload.guestBasketTotal;
      state.basketItems = action.payload.guestBasketItems;
    },
    resetBasket: (state) => {
      state.basketId = 0;
      state.basketTotal = 0;
      state.basketItems = [];
      state.outOfStockSkus = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBasket.pending, (state) => {
        state.isLoading = true;
        state.isBasketLoading = true;
      })
      .addCase(getBasket.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoading = false;
        state.isBasketLoading = false;
      })
      .addCase(addBasketItem.pending, (state) => {
        state.isLoading = true;
        state.isBasketLoading = true;
        state.isLoadingUpdate = true;
        state.isAdded = false;
      })
      .addCase(addBasketItem.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoading = false;
        state.isBasketLoading = false;
        state.isLoadingUpdate = false;
        state.isAdded = true;
      })
      .addCase(updateBasketItem.pending, (state) => {
        state.isLoadingUpdate = true;
      })
      .addCase(updateBasketItem.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoadingUpdate = false;
      })
      .addCase(addPreviousOrderBasketItem.pending, (state) => {
        state.isLoading = true;
        state.isBasketLoading = true;
      })
      .addCase(addPreviousOrderBasketItem.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoading = false;
        state.isBasketLoading = false;
      })
      .addCase(removeBasketItem.pending, (state) => {
        state.isLoading = true;
        state.isBasketLoading = true;
      })
      .addCase(removeBasketItem.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoading = false;
        state.isBasketLoading = false;
      })
      .addCase(removeBasketItem.rejected, (state) => {
        state.hasError = true;
        state.isBasketLoading = false;
      })
      .addCase(removeOutOfStockBasketItem.pending, (state) => {
        state.isLoading = true;
        state.isBasketLoading = true;
      })
      .addCase(removeOutOfStockBasketItem.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoading = false;
        state.isBasketLoading = false;
      })
      .addCase(removeOutOfStockBasketItem.rejected, (state) => {
        state.hasError = true;
      })
      .addCase(mergeGuestItemsToBasket.pending, (state) => {
        state.isLoading = true;
        state.isBasketLoading = true;
      })
      .addCase(mergeGuestItemsToBasket.fulfilled, (state, action) => {
        state.basketId = action.payload.basketID;
        state.basketTotal = action.payload.basketTotal;
        state.basketItems = action.payload.basketItems;
        state.outOfStockSkus = action.payload.outOfStockSkus;
        state.isLoading = false;
        state.isBasketLoading = false;
      })
      .addCase(mergeGuestItemsToBasket.rejected, (state) => {
        state.isLoading = false;
        state.isBasketLoading = false;
      });
  },
});

export const { setGuestBasket, resetBasket } = BasketSlice.actions;
export default BasketSlice.reducer;
