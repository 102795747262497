import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from 'src/redux/store';
import { AddressResponse } from 'types/address';
import { ByAddressResponse, ByPostCodeResponse } from 'types/sorted';

export type DeliveryOption = 'delivery' | 'collect' | '';

export interface CheckoutState {
  deliveryOption: DeliveryOption;
  deliveryPrice: number;
  deliveryAddress: AddressResponse | null;
  reference: string;
  sortedOption: ByAddressResponse | ByPostCodeResponse | null;
  deliveryTime: string;
}

const initialState: CheckoutState = {
  deliveryOption: '',
  deliveryPrice: 0,
  deliveryAddress: null,
  reference: '',
  sortedOption: null,
  deliveryTime: '',
};

const hydrate = createAction<RootState>(HYDRATE);

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setDeliveryOption: (state, action: PayloadAction<DeliveryOption>) => {
      state.deliveryOption = action.payload;
    },
    setDeliveryPrice: (state, action: PayloadAction<number>) => {
      state.deliveryPrice = action.payload;
    },
    setReference: (state, action: PayloadAction<string>) => {
      state.reference = action.payload;
    },
    setDeliveryAddress: (state, action: PayloadAction<AddressResponse | null>) => {
      state.deliveryAddress = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload[checkoutSlice.name],
      };
    });
  },
});

export const { setDeliveryOption, setDeliveryPrice, setReference, setDeliveryAddress } =
  checkoutSlice.actions;

export const selectDeliveryOption = (state: RootState): DeliveryOption =>
  state.checkout.deliveryOption;

export const selectDeliveryPrice = (state: RootState): number => state.checkout.deliveryPrice;

export const selectReference = (state: RootState): string => state.checkout.reference;

export const selectDeliveryAddress = (state: RootState): AddressResponse | null =>
  state.checkout.deliveryAddress;

export default checkoutSlice.reducer;
