import { Basket } from 'types/product';

export const generatePriceFromQuantity = (
  prices: {
    quantity: number;
    price: number;
    salePrice: number;
    saving: number;
  }[],
  quantity: number,
  oneQuantityPrice: number
): number => {
  const filteredPrice = prices.filter((price) => quantity >= price.quantity);
  return filteredPrice.length === 0
    ? oneQuantityPrice
    : filteredPrice[filteredPrice.length - 1].price;
};

export const setPricesBasket = (basket: Basket[]): Basket[] => {
  const basketItems = [...basket];
  basketItems.forEach((item) => {
    const itemPrices = [...item.prices.priceBreaks];
    const itemPricesVAT = [...item.prices.vatPriceBreaks];
    const sortedPricedItems = itemPrices
      .sort((prev, curr) => curr.price - prev.price)
      .map((price) => ({
        ...price,
        // price: price.price,
        price: Object.prototype.hasOwnProperty.call(price, 'currentPrice')
          ? price.currentPrice
          : price.price,
      }));

    item.prices.price = item.prices.salePrice
      ? item.prices.price
      : generatePriceFromQuantity(sortedPricedItems, item.quantity, item.prices.price);

    const sortedPricedItemsVAT = itemPricesVAT
      .sort((prev, curr) => curr.price - prev.price)
      .map((price) => ({
        ...price,
        // price: price.price,
        price: Object.prototype.hasOwnProperty.call(price, 'currentPrice')
          ? price.currentPrice
          : price.price,
      }));
    item.prices.vatPrice = item.prices.salePrice
      ? item.prices.vatPrice
      : generatePriceFromQuantity(sortedPricedItemsVAT, item.quantity, item.prices.vatPrice);
  });

  return basketItems;
};
