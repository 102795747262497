import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import type { RootState } from 'src/redux/store';

interface VATProps {
  showVat: boolean;
}

const initialState: VATProps = {
  showVat: false,
};

const VATSlice = createSlice({
  name: 'VAT',
  initialState,
  reducers: {
    setVAT: (state, action: PayloadAction<boolean>) => {
      localStorage.setItem('VAT', action.payload.toString());
      state.showVat = action.payload;
    },
  },
});

export const { setVAT } = VATSlice.actions;
export const selectVAT = (state: RootState) => state.vat.showVat;
export default VATSlice.reducer;
