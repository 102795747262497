import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'src/redux/store';

interface DYContextProps {
  isDYContextSet: boolean;
}

const initialState: DYContextProps = {
  isDYContextSet: false,
};

const DYContextSlice = createSlice({
  name: 'dyContext',
  initialState,
  reducers: {
    setIsDYContextSet: (state, action: PayloadAction<boolean>) => {
      state.isDYContextSet = action.payload;
    },
  },
});

export const { setIsDYContextSet } = DYContextSlice.actions;
export const selectIsDYContextSet = (state: RootState): boolean => state.dyContext.isDYContextSet;
export default DYContextSlice.reducer;
