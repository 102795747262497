export enum DYContext {
  Homepage = 'HOMEPAGE',
  Category = 'CATEGORY',
  Product = 'PRODUCT',
  Cart = 'CART',
  Other = 'OTHER',
}

export interface DYCartItem {
  productId: string;
  quantity: number;
  itemPrice: number;
}
