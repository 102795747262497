import Link from 'next/link';
import { COMPANY_ID_FE } from 'src/constants/mtGateway';
import Company from 'types/company';
const companyID = Number(COMPANY_ID_FE);

const footerDataID = [
  {
    title: 'About Us',
    route: '/promise',
  },
  {
    title: 'Reasons to shop',
    route: '/reasons-to-shop',
  },
  {
    title: 'Delivery',
    route: '/information/delivery',
  },
  {
    title: 'Track Your Order',
    route: '/track',
  },
  {
    title: 'Returns / Refunds',
    route: '/information/returns',
  },
  {
    title: 'Contact Us',
    route: '/contact',
  },
  {
    title: 'Refer a Friend',
    route: '/refer?situation=webfooter',
  },
  {
    title: 'Careers',
    route: 'external/https://traders.jobs.people-first.com/jobs/search',
  },
  {
    title: 'Research',
    route: '/research',
  },
  {
    title: 'Blog',
    route: '/blog',
  },
  // {
  //   title: 'Commercial Accounts',
  //   route: '/commercial',
  // },
  {
    title: 'Declaration of Performance',
    route: '/dop',
  },
  {
    title: 'Free Catalogue',
    route: '/free-catalogue',
  },
  {
    title: 'TradeXtra',
    route: '/tradextra',
  },
  {
    title: 'Trade Counter',
    route: '/trade-counter',
  },
  {
    title: 'Modern Slavery Statement',
    route:
      'external/https://img-cdn.manutantraders.com/image/upload/v1697638144/shared/H_S_Policy_2023_Page_13.pdf',
  },
  {
    title: 'New Products',
    route: '/browse/new',
  },
  {
    title: 'Our Brands',
    route: '/our-brands',
  },
  {
    title: 'A-Z of Brands',
    route: '/brands',
  },
  {
    title: 'Stress In The Trade',
    route: '/research/mental-health-in-the-trades',
  },
  {
    title: 'Construction Funding',
    route: '/research/construction-funding',
  },
  {
    title: 'Delivery Note',
    route: '/delivery-note',
  },
];

const footerDataED = [
  {
    title: 'About Us',
    route: '/promise',
  },
  {
    title: 'Reasons to shop',
    route: '/reasons-to-shop',
  },
  {
    title: 'Delivery',
    route: '/information/delivery',
  },
  {
    title: 'Track Your Order',
    route: '/track',
  },
  {
    title: 'Returns / Refunds',
    route: '/information/returns',
  },
  {
    title: 'Contact Us',
    route: '/contact',
  },
  {
    title: 'Refer a Friend',
    route: '/refer?situation=webfooter',
  },
  {
    title: 'Careers',
    route: 'external/https://traders.jobs.people-first.com/jobs/search',
  },
  {
    title: 'Blog',
    route: '/blog',
  },
  // {
  //   title: 'Commercial Accounts',
  //   route: '/commercial',
  // },
  // {
  //   title: 'Free Catalogue',
  //   route: '/free-catalogue',
  // },
  // {
  //   title: 'Trusted Trade Account',
  //   route: '/tradeaccount',
  // },
  {
    title: 'Trade Counter',
    route: '/trade-counter',
  },
  {
    title: 'Modern Slavery Statement',
    route:
      'external/https://img-cdn.manutantraders.com/image/upload/v1697638144/shared/H_S_Policy_2023_Page_13.pdf',
  },
  {
    title: 'A-Z of Brands',
    route: '/brands',
  },
  {
    title: 'WEEE Directive',
    route: '/weee-directive',
  },
  {
    title: 'Mood Lighting',
    route: '/browse/lighting/lamps-and-tubes/mood-lighting',
  },
];

interface IProps {
  showInternalLinks: boolean;
}

const InternalLinks: React.FC<IProps> = ({ showInternalLinks }) => {
  const footerDataToRender = companyID === Company.Ironmongery ? footerDataID : footerDataED;

  return (
    <ul
      className={`internal-links flex flex-col flex-wrap transition-all duration-500 overflow-hidden 1192px:overflow-visible 1192px:max-h-screen 1192px:opacity-100 1192px:visible ${
        showInternalLinks ? 'max-h-screen opacity-100 visible' : 'max-h-0 opacity-0 invisible'
      }`}
    >
      {footerDataToRender.map((link) => (
        <li
          className="mr-0 mt-5 md:mt-6 1192px:mr-8 hover:underline text-xs md:text-base"
          key={link.title}
        >
          <Link
            href={link.route.includes('external/') ? link.route.split('external/')[1] : link.route}
          >
            <a
              href={
                link.route.includes('external/') ? link.route.split('external/')[1] : link.route
              }
              onClick={() => {
                if (link.title === 'Refer a Friend') {
                  window.location.replace(
                    link.route.includes('external/') ? link.route.split('external/')[1] : link.route
                  );
                }
              }}
              className="hover:text-current"
            >
              {link.title}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default InternalLinks;
