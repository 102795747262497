import { useState } from 'react';

export type GeoLocationStatus = 'Unsupported' | 'Locating' | 'Failed' | 'Success';

interface GeoLocationState {
  latitude: number | null;
  longitude: number | null;
  status: GeoLocationStatus | null;
}

interface useGeoLocationReturn extends GeoLocationState {
  getLocation: () => void;
  setStatus: (status: GeoLocationStatus) => void;
}

const useGeoLocation = (
  callback?: ({ latitude, longitude }: { latitude: number; longitude: number }) => void
): useGeoLocationReturn => {
  const [geolocation, setGeolocation] = useState<GeoLocationState>({
    latitude: null,
    longitude: null,
    status: null,
  });

  const getLocation = (): void => {
    if (!navigator.geolocation) {
      setGeolocation((prevState) => {
        return {
          ...prevState,
          status: 'Unsupported',
        };
      });
    } else {
      setGeolocation((prevState) => {
        return {
          ...prevState,
          status: 'Locating',
        };
      });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setGeolocation((prevState) => {
            return {
              ...prevState,
              latitude,
              longitude,
            };
          });
          if (callback) {
            callback({
              latitude,
              longitude,
            });
          }
        },
        () => {
          setGeolocation({ latitude: null, longitude: null, status: 'Failed' });
        }
      );
    }
  };

  const setStatus = (status: GeoLocationStatus) => {
    setGeolocation((prevState) => {
      return {
        ...prevState,
        status,
      };
    });
  };

  return { ...geolocation, setStatus, getLocation };
};

export default useGeoLocation;
