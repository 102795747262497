import { useState, useEffect } from 'react';
import Company from 'types/company';
import { Basket } from 'types/product';
import { useAppDispatch } from '@hooks/redux';
import { setVAT } from 'src/redux/VATSlice';

interface summaryTableProps {
  basketItems: Basket[];
  basketTotal: number;
  showVat: boolean;
  companyID: Company;
  deliveryThreshold: number;
  deliveryFee: number;
}

interface summaryTableRowProps {
  rowName?: string;
  value: string | number;
  highlightName?: boolean;
  highlightValue?: boolean;
  isTotal?: boolean;
  companyID: Company;
  showVat?: boolean;
}

const HeaderBasketSummaryTableRow = ({
  rowName,
  value,
  highlightName,
  highlightValue,
  isTotal,
  companyID,
}: summaryTableRowProps): JSX.Element => (
  <div
    className={`rhd-basket__summary__table__row ${
      isTotal ? 'rhd-basket__summary__table__row--total' : ''
    }`}
  >
    <p
      className={`${
        highlightName ? `highlight ${companyID === Company.Ironmongery ? '' : 'highlight--ed'}` : ''
      }`}
    >
      {rowName}
    </p>
    <p
      className={`${
        highlightValue
          ? `highlight ${companyID === Company.Ironmongery ? '' : 'highlight--ed'}`
          : ''
      }`}
    >
      {typeof value === 'string' ? '' : '£'}
      {typeof value === 'string' ? value : value ? Number(value).toFixed(2) : 0.0}
    </p>
  </div>
);

const HeaderBasketSummaryTable = ({
  basketItems,
  basketTotal,
  showVat,
  companyID,
  deliveryThreshold,
  deliveryFee,
}: summaryTableProps): JSX.Element => {
  const [hasSale, setHasSale] = useState<boolean>(false);
  const [hasFreeDel, setHasFreeDel] = useState<boolean>(false);
  const [totalSaving, setTotalSaving] = useState<number>(0);
  const [surcharge, setSurcharge] = useState<boolean>(false);
  const [vat, setVat] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [originalPriceTotal, setOriginalPriceTotal] = useState<number[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const vatValue =
      (20 / 100) * (basketTotal + (hasFreeDel ? 0 : deliveryFee) + (surcharge ? 4.95 : 0));
    setVat(Number(vatValue));
  }, [basketItems, basketTotal, hasFreeDel, surcharge]);

  useEffect(() => {
    const totalValue =
      basketTotal +
      basketTotal * (20 / 100) +
      (hasFreeDel ? 0 : deliveryFee + deliveryFee * (20 / 100)) +
      (surcharge ? 4.95 + 4.95 * (20 / 100) : 0);
    setTotal(Number(totalValue));
  }, [basketItems, basketTotal, hasFreeDel, surcharge]);

  useEffect(() => {
    const isLongLength = basketItems.find((i) => i.isLongLength === true);
    setSurcharge(!!isLongLength);
  }, [basketItems]);

  useEffect(() => {
    if (basketTotal >= +deliveryThreshold) {
      setHasFreeDel(true);
    } else {
      setHasFreeDel(false);
    }
  }, [basketTotal]);

  useEffect(() => {
    basketItems.forEach((item) => {
      if (item.prices.salePrice > 0) {
        setHasSale(true);
      }
    });

    if (basketItems.length > 0) {
      const total = [];
      basketItems.forEach((item) => {
        total.push(item.prices.price * item.quantity);
      });
      setOriginalPriceTotal(total);
    }
  }, [basketItems]);

  useEffect(() => {
    if (originalPriceTotal.length > 0) {
      setTotalSaving(originalPriceTotal.reduce((a: number, b: number) => a + b) - basketTotal);
    }
  }, [originalPriceTotal, basketTotal]);

  const toggleSwitch = (): void => {
    dispatch(setVAT(!showVat));
  };

  const toggleSwitchPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      dispatch(setVAT(!showVat));
    }
  };

  return (
    <div className="rhd-basket__summary">
      <div className="rhd-basket__summary__toggle">
        <p>Show VAT</p>
        <div
          role="checkbox"
          title={
            !showVat ? 'Click to show prices including VAT.' : 'Click to show prices excluding VAT.'
          }
          tabIndex={0}
          aria-checked={showVat}
          className={`switch ${
            companyID === Company.Ironmongery ? 'switch--default' : 'switch--ed'
          }`}
          onClick={() => {
            toggleSwitch();
          }}
          onKeyDown={toggleSwitchPress}
        >
          <div
            className={`switch-toggle ${showVat ? 'switch-toggle--on' : 'switch-toggle--off'}`}
          />
        </div>
      </div>
      <div className="rhd-basket__summary__table">
        <HeaderBasketSummaryTableRow
          rowName={`Subtotal (${showVat ? 'Inc. VAT' : 'Excl. VAT'})`}
          value={showVat ? basketTotal + basketTotal * (20 / 100) : basketTotal}
          companyID={companyID}
        />
        {hasSale && (
          <HeaderBasketSummaryTableRow
            rowName="You Save"
            value={
              showVat ? Number((totalSaving * 1.2).toFixed(2)) : Number(totalSaving.toFixed(2))
            }
            companyID={companyID}
            showVat={showVat}
          />
        )}
        <HeaderBasketSummaryTableRow
          rowName={`Delivery (${showVat ? 'Inc. VAT' : 'Excl. VAT'})`}
          value={
            hasFreeDel ? 'Free' : showVat ? deliveryFee + deliveryFee * (20 / 100) : deliveryFee
          }
          highlightValue={hasFreeDel}
          companyID={companyID}
        />
        {surcharge ? (
          <HeaderBasketSummaryTableRow
            rowName="Large Item Surcharge"
            value={showVat ? 4.95 + 4.95 * (20 / 100) : 4.95}
            companyID={companyID}
          />
        ) : null}
        {!showVat ? (
          <HeaderBasketSummaryTableRow rowName="VAT @ 20%" value={vat} companyID={companyID} />
        ) : null}
        <HeaderBasketSummaryTableRow
          isTotal
          rowName="Total"
          value={total}
          highlightName
          highlightValue
          companyID={companyID}
        />
      </div>
    </div>
  );
};

export default HeaderBasketSummaryTable;
