import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';

interface HistoryState {
  history: string[];
}

const initialState: HistoryState = {
  history: [],
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistory: (state, action: PayloadAction<string[]>) => {
      state.history = action.payload;
    },
  },
});

export const { setHistory } = historySlice.actions;

export const selectHistory = (state: RootState): string[] => state.history.history;

export default historySlice.reducer;
