import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface setNavMenuProps {
  baseUrl: string | undefined;
  apiKey: string | undefined;
}

const initialState = {
  productCategory: [],
  brandItems: {},
  isFetchingData: false,
};

export const setNavMenu = createAsyncThunk(
  'category/setNavMenu',
  async (param: setNavMenuProps) => {
    const { baseUrl, apiKey } = param;
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/product/GetNavMenu`,
      headers: {
        // universe: companyID === Company.Ironmongery ? 'ironmongery' : 'electrical',
        'ocp-apim-subscription-key': apiKey,
      },
    });
    return response.data;
  }
);

export const navMenuSlice = createSlice({
  name: 'navMenu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setNavMenu.pending, (state) => {
        // Add user to the state array
        state.isFetchingData = true;
      })
      .addCase(setNavMenu.fulfilled, (state, action) => {
        // Add user to the state array
        state.productCategory = action.payload.categoryTree;
        state.brandItems = action.payload.brands;
        state.isFetchingData = false;
      });
  },
});

export default navMenuSlice.reducer;
