import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from 'src/redux/store';
import Company from 'types/company';
import { ByPostCodeResponse } from 'types/sorted';

interface ConfigState {
  token: string;
  company: Company;
  summaryOpen: boolean;
  collectDetailsOpen: {
    active: boolean;
    location: ByPostCodeResponse | null;
  };
  newAddress: boolean;
  editAddress: {
    active: boolean;
    id: string;
  };
}

const initialState: ConfigState = {
  token: '',
  company: Company.Ironmongery,
  summaryOpen: false,
  collectDetailsOpen: {
    active: false,
    location: null,
  },
  newAddress: false,
  editAddress: {
    active: false,
    id: '',
  },
};

const hydrate = createAction<RootState>(HYDRATE);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
    setSummaryOpen: (state, action: PayloadAction<boolean>) => {
      state.summaryOpen = action.payload;
    },
    setCollectDetailsOpen: (
      state,
      action: PayloadAction<{ active: boolean; location: ByPostCodeResponse | null }>
    ) => {
      state.collectDetailsOpen = action.payload;
    },
    setNewAddress: (state, action: PayloadAction<boolean>) => {
      state.newAddress = action.payload;
    },
    setEditAddress: (state, action: PayloadAction<{ active: boolean; id: string }>) => {
      state.editAddress = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload[configSlice.name],
      };
    });
  },
});

export const { setToken, setCompany, setSummaryOpen, setNewAddress, setEditAddress } =
  configSlice.actions;

export const selectToken = (state: RootState): string => state.config.token;

export const selectCompany = (state: RootState): Company => state.config.company;

export const selectSummaryOpen = (state: RootState): boolean => state.config.summaryOpen;

export const selectCollectDetailsOpen = (
  state: RootState
): { active: boolean; location: ByPostCodeResponse | null } => state.config.collectDetailsOpen;

export const selectNewAddress = (state: RootState): boolean => state.config.newAddress;

export const selectEditAddress = (state: RootState): { active: boolean; id: string } =>
  state.config.editAddress;

export default configSlice.reducer;
