import config from './config';

interface apiProps {
  url: string;
  apiConfiguration: {
    timeout: number;
    headers: {
      'Ocp-Apim-Subscription-Key': string | undefined;
      // universe: string;
    };
  };
}

export const productDetailServiceApiUrl = (skucode: string): apiProps => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const url = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/product/ProductDetail/${skucode}?companyID=${companyID}`;
  const apiConfiguration = config(
    Number(process.env.NEXT_PUBLIC_COMPANY_ID),
    process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY
  );

  return { url, apiConfiguration };
};

export const productServiceApiUrl = (pathname: string): apiProps => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const url = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/product${pathname}?companyID=${companyID}`;
  const apiConfiguration = config(
    Number(process.env.NEXT_PUBLIC_COMPANY_ID),
    process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY
  );

  return { url, apiConfiguration };
};

export const productGoogleShoppingServiceApiUrl = (pathname: string): apiProps => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const url = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/product${pathname}${
    pathname.includes('?') ? '&' : '?'
  }companyID=${companyID}`;
  const apiConfiguration = config(
    Number(process.env.NEXT_PUBLIC_COMPANY_ID),
    process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY
  );

  return { url, apiConfiguration };
};

export const facetServiceApiUrl = (): apiProps => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const url = `${process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL}/product/FacetsWhitelist?companyID=${companyID}`;
  const apiConfiguration = config(
    Number(process.env.NEXT_PUBLIC_COMPANY_ID),
    process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY
  );

  return { url, apiConfiguration };
};

export const metaServiceApiUrl = (slug: string | string[]): apiProps => {
  const companyID = Number(process.env.NEXT_PUBLIC_COMPANY_ID);
  const url = `${
    process.env.NEXT_PUBLIC_MANUTAN_GATEWAY_URL
  }/metadata/?companyID=${companyID}&slug=${slug.slice(1)}`;
  const apiConfiguration = config(
    Number(process.env.NEXT_PUBLIC_COMPANY_ID),
    process.env.NEXT_PUBLIC_MANUTAN_CHECKOUT_API_KEY
  );

  return { url, apiConfiguration };
};
