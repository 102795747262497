interface componentLoader {
  className?: string;
}
function InfiniteLoader({ className }: componentLoader): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={className}
    >
      <g id="navigation-icon-basket-load" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <circle cx="84" cy="50" r="0">
          <animate
            attributeName="r"
            values="10;0;0;0;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="0s"
          />
          <animate
            attributeName="cx"
            values="84;84;84;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
        <circle cx="16" cy="50" r="7.592">
          <animate
            attributeName="r"
            values="0;10;10;10;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="-1.25s"
          />
          <animate
            attributeName="cx"
            values="16;16;50;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="-1.25s"
          />
        </circle>
        <circle cx="84" cy="50" r="2.408">
          <animate
            attributeName="r"
            values="0;10;10;10;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="-0.625s"
          />
          <animate
            attributeName="cx"
            values="16;16;50;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="-0.625s"
          />
        </circle>
        <circle cx="75.812" cy="50" r="10">
          <animate
            attributeName="r"
            values="0;10;10;10;0"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="0s"
          />
          <animate
            attributeName="cx"
            values="16;16;50;84;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
        <circle cx="41.812" cy="50" r="10">
          <animate
            attributeName="r"
            values="0;0;10;10;10"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="0s"
          />
          <animate
            attributeName="cx"
            values="16;16;16;50;84"
            keyTimes="0;0.25;0.5;0.75;1"
            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
            calcMode="spline"
            dur="2.5s"
            repeatCount="indefinite"
            begin="0s"
          />
        </circle>
      </g>
    </svg>
  );
}

export default InfiniteLoader;
