import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from 'src/redux/store';

interface ProgressState {
  percentage: number;
  delivery: boolean;
  payment: boolean;
  confirmed: boolean;
}

export interface ProgressSteps {
  delivery: boolean;
  payment: boolean;
  confirmed: boolean;
}

const initialState: ProgressState = {
  percentage: 10,
  delivery: true,
  payment: false,
  confirmed: false,
};

const hydrate = createAction<RootState>(HYDRATE);

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload;
    },
    setDelivery: (state, action: PayloadAction<boolean>) => {
      state.delivery = action.payload;
    },
    setPayment: (state, action: PayloadAction<boolean>) => {
      state.payment = action.payload;
    },
    setConfirmed: (state, action: PayloadAction<boolean>) => {
      state.confirmed = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(hydrate, (state, action) => {
      return {
        ...state,
        ...action.payload[progressSlice.name],
      };
    });
  },
});

export const { setProgress, setDelivery, setPayment, setConfirmed } = progressSlice.actions;

export const selectProgress = (state: RootState): number => state.progress.percentage;

export const selectProgressSteps = (state: RootState): ProgressSteps => {
  return {
    delivery: state.progress.delivery,
    payment: state.progress.payment,
    confirmed: state.progress.confirmed,
  };
};

export default progressSlice.reducer;
