interface PageProps {
  children: JSX.Element;
  className?: string | undefined;
  noPaddingRight?: boolean;
}

function PageWrapper({ children, className, noPaddingRight }: PageProps): JSX.Element {
  return (
    <div className={`root-container ${noPaddingRight ? 'pr-0' : ''}`}>
      <div className="root-container__inner">
        <div className={`root-container__main ${className ? className : ''}`}>{children}</div>
      </div>
    </div>
  );
}

export default PageWrapper;
