import { useField, FieldHookConfig } from 'formik';
import Button, { Variant, Size } from '@components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { GeoLocationStatus } from '@hooks/useGeolocation';

type PostcodeInputProps = {
  label: string;
  buttonType: 'button' | 'submit';
  buttonOnClick?: () => void;
  InputKeyDown: (e: React.KeyboardEvent) => void;
  getLocation: () => void;
  geolocationStatus: GeoLocationStatus | null;
  noFindLocation?: boolean;
} & FieldHookConfig<string>;

const PostcodeInput = (props: PostcodeInputProps): React.ReactElement => {
  const [field, meta] = useField(props);
  const {
    label,
    type,
    buttonType,
    buttonOnClick,
    InputKeyDown,
    noFindLocation,
    geolocationStatus,
    getLocation,
  } = props;
  return (
    <div className="w-full flex flex-col xl:flex-row xl:gap-6">
      <div className="w-full xl:w-1/2 relative mb-4 xl:mb-0">
        <input
          {...field}
          value={field.value ? field.value : ''}
          name={field.name}
          type={type}
          className={`placeholder-grey-light text-black-lighter border-b ${
            meta.touched && meta.error ? 'border-error' : 'border-#353535'
          }  focus:outline-none z-1 h-10 w-full`}
          placeholder={label}
          autoComplete="off"
          onKeyDown={InputKeyDown}
        />

        {meta.touched && meta.error ? (
          <div className="text-xs text-red mt-1">{meta.error}</div>
        ) : null}
      </div>
      <div className="flex gap-4 xl:w-1/2">
        <div className="w-1/2">
          <Button
            name="postcode-lookup"
            type={buttonType}
            variant={field.value && !meta.error ? Variant.BLACK : Variant.DISABLED}
            size={Size.SMALL}
            onClick={buttonOnClick}
            disabled={!field.value}
          >
            <div className="h-input w-full flex items-center justify-center align-middle text-sm leading-tight">
              Find Address
            </div>
          </Button>
        </div>
        {!noFindLocation && (
          <div className="w-1/2">
            <Button
              type="button"
              variant={Variant.BLACK_OUTLINE}
              size={Size.SMALL}
              onClick={() => getLocation()}
            >
              <div className="w-full flex items-center justify-center align-middle text-sm leading-tight">
                {geolocationStatus === 'Locating' ? (
                  <>
                    <span>Finding Location</span>
                    <FontAwesomeIcon icon={faSpinner} className="w-2.5 ml-2 animate-spin" />
                  </>
                ) : (
                  <>
                    <span className="mr-2">Find location</span>
                  </>
                )}
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostcodeInput;
