import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'src/redux/store';

interface foundItProps {
  isFoundItExp: boolean;
}

const initialState: foundItProps = {
  isFoundItExp: false,
};

const FoundItSlice = createSlice({
  name: 'foundIt',
  initialState,
  reducers: {
    setIsFoundItExp: (state, action: PayloadAction<boolean>) => {
      state.isFoundItExp = action.payload;
    },
  },
});

export const { setIsFoundItExp } = FoundItSlice.actions;
export const selectIsFoundItExp = (state: RootState): boolean => state.foundIt.isFoundItExp;
export default FoundItSlice.reducer;
