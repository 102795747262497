/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { FindItem, GeoLocationItem } from 'types/loqate';

interface AddressSelectProps {
  addressList: FindItem[] | GeoLocationItem[];
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const AddressSelect = ({
  addressList,
  handleSelectChange,
}: AddressSelectProps): React.ReactElement => {
  return (
    <select
      data-testid="address-select"
      name="address-select"
      className="mt-2 w-full h-40px py-2 text-black-lighter border-b border-#353535 focus-visible:outline-none mb-4"
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleSelectChange(e)}
    >
      <option data-testid="address-select-option" value="">
        Select Address
      </option>
      {addressList.map((address) => (
        <option
          data-testid="address-select-option"
          key={address.Id}
          value={address.Id}
        >{`${address.Text}, ${address.Description}`}</option>
      ))}
    </select>
  );
};

export default AddressSelect;
