import Company from 'types/company';

interface apiConfiguration {
  timeout: number;
  headers: {
    'Ocp-Apim-Subscription-Key': string | undefined;
    universe: string;
    companyId: Company;
  };
}

export const config = (companyID?: Company, apiKey?: string): apiConfiguration => {
  return {
    timeout: 1000 * 10,
    headers: {
      'Ocp-Apim-Subscription-Key': apiKey,
      universe: `${companyID === Company.Ironmongery ? 'ironmongery' : 'electrical'}`,
      companyId: companyID ? companyID : 1,
    },
  };
};

export default config;
